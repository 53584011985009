import { FC } from "react";

import Text from "apps/website/components/base/Text/Text";
import { IconSvg } from "apps/website/components/base/Icon/Icon.map";
import Icon from "apps/website/components/base/Icon/Icon";
import {
  Display,
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Spacer from "apps/website/components/layout/Spacer/Spacer";

import { SelectOptionTag } from "../SelectOptionTag/SelectOptionTag";

interface ICheckableSelectOption {
  icon?: IconSvg;
  name: string;
  secondaryText?: string;
  tertiaryText?: string;
  aGroupMemberHasSecondaryText?: boolean;
  aGroupMemberHasTertiaryText?: boolean;
  optionIndex?: number;
  tag?: string
  display?: Display;
}

export const CheckableSelectOption: FC<ICheckableSelectOption> = (
  {
    icon,
    name,
    secondaryText,
    tertiaryText,
    aGroupMemberHasSecondaryText,
    aGroupMemberHasTertiaryText,
    tag,
    optionIndex,
    display = "title",
  },
) => {

  const hasAdvancedData = aGroupMemberHasSecondaryText || aGroupMemberHasTertiaryText || tag || icon;
  const wrapperComputedClasses = hasAdvancedData
    ? `flex-col h-full w-full px-2 transform ${!icon && ((optionIndex ?? 0) % 2 === 0 ? "rotate-1" : "-rotate-1")} ${!icon && "pb-2 pt-4 py-12 lg:py-16"}`
    : "h-12 rounded-full px-4";

  const detailComputedClasses = hasAdvancedData
    ? "flex flex-col h-full relative"
    : "w-full";
  return (
    <>
      <div className={`${wrapperComputedClasses} bg-white cursor-pointer box-border border-2 flex flex-1 justify-center items-center border-solid border-black peer-checked:bg-black peer-checked:text-white relative`}>
        { tag && (
          <SelectOptionTag>
            { tag }
          </SelectOptionTag>
        ) }
        <div className={detailComputedClasses}>
          { icon ? (
            <Icon icon={icon} color="inherit" size="radio" />
          ) : (
            <Text tag="span" display={display} align="center" color="inherit" size={display === "subtitle" ? "xs" : "default"} className="block first-letter:capitalize">{ name }</Text>
          ) }
          { aGroupMemberHasSecondaryText && (<Text tag="span" align="center" color="inherit" size="sm" className="pt-2">{ secondaryText || <>&nbsp;</> }</Text>) }
          { aGroupMemberHasTertiaryText && (<Text tag="span" size="2xs" align="center" color="inherit" className="pt-2">{ tertiaryText || <>&nbsp;</> }</Text>) }
        </div>
      </div>
      { icon && (
        <>
          <Spacer size="sm" />
          <Text tag="span" align="center" color="inherit" size={legacySizeCollectionMap.bodySm}>{ name }</Text>
        </>
      ) }
    </>
  );
};
